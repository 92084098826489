import React, { useState } from 'react';
import { faArrowLeftRotate, faBackspace, faBackward, faBackwardStep, faBars, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { logout } = useAuth();
    const navigate = useNavigate();

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleSignOut = async () => {
        await logout();
        navigate('/'); // Redirect after sign out
    };

    return (
        <>
        <div className={`fixed z-10 top-0 left-0 h-screen p-4 bg-gray-800 text-white w-64 transition-transform ${isOpen ? 'translate-x-0 py-10' : '-translate-x-full'} md:relative md:translate-x-0`}>
            <h2 className="text-2xl font-semibold mb-6">EvalSpot</h2>
            <nav>
            <ul>
                <li className="mb-4">
                <Link to="/admin" className="text-lg hover:text-gray-400 cursor-pointer">
                    <FontAwesomeIcon icon={faUser} className='mr-2'/> Admin
                </Link>
                </li>
                <li className="mb-4 text-lg hover:text-gray-400 cursor-pointer" onClick={handleSignOut}>
                    <FontAwesomeIcon icon={faSignOutAlt} className='mr-2'/> Sign Out
                </li>
            </ul>
            </nav>
            {/* <button
            className="mt-6 w-full py-2 px-4 bg-red-500 hover:bg-red-700 transition duration-300 rounded"
            >
            Sign Out
            </button> */}
        </div>

        <button 
            className={`fixed z-20 top-4 left-4 md:hidden ${isOpen ? 'text-white' : 'text-black'}`}
            onClick={toggleSidebar}
        >
            <FontAwesomeIcon icon={faBars} />
        </button>
        </>
    );
};

export default Sidebar;


