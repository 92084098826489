import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, query, where, getDocs, writeBatch } from 'firebase/firestore';
import { db } from '../firebase';

const EditForm = () => {
  const { formId } = useParams();
  const navigate = useNavigate();
  const [formName, setFormName] = useState('');
  const [questions, setQuestions] = useState([]);
  const [colorTheme, setColorTheme] = useState('');

  useEffect(() => {
    fetchFormDetails();
  }, [formId]);

  const fetchFormDetails = async () => {
    try {
      const formDocRef = doc(db, 'forms', formId);
      const formDoc = await getDoc(formDocRef);

      if (formDoc.exists()) {
        const formData = formDoc.data();
        setFormName(formData.formName);
        setQuestions(formData.questions);
        setColorTheme(formData.colorTheme);
      }
    } catch (error) {
      console.error('Error fetching form details:', error);
    }
  };

  const handleSave = async () => {
    try {
      const formDocRef = doc(db, 'forms', formId);
      await updateDoc(formDocRef, {
        formName,
        questions,
        colorTheme,
      });
      navigate('/admin');
    } catch (error) {
      console.error('Error updating form:', error);
    }
  };

  const handleColorChange = (event) => {
    setColorTheme(event.target.value);
  };

  const handleQuestionChange = (index, newText) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].text = newText;
    setQuestions(updatedQuestions);
  };

  const handleQuestionTypeChange = (index, newType) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].type = newType;
    if (newType === 'checkbox') {
      updatedQuestions[index].options = updatedQuestions[index].options || [''];
    } else {
      delete updatedQuestions[index].options;
    }
    setQuestions(updatedQuestions);
  };

  const handleLabelChange = (index, label, newText) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][label] = newText;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (qIndex, oIndex, newText) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options[oIndex] = newText;
    setQuestions(updatedQuestions);
  };

  const handleAddOption = (qIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options.push('');
    setQuestions(updatedQuestions);
  };

  const handleDeleteOption = (qIndex, oIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options = updatedQuestions[qIndex].options.filter((_, i) => i !== oIndex);
    setQuestions(updatedQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { text: '', type: 'text', options: [] }]);
  };

  const handleDeleteQuestion = async (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);

    try {
      // Fetch and update responses linked to the deleted question index
      const responsesQuery = query(collection(db, 'responses'), where('formId', '==', formId));
      const responsesSnapshot = await getDocs(responsesQuery);
      const batch = writeBatch(db);

      responsesSnapshot.forEach((docSnapshot) => {
        const responseData = docSnapshot.data();
        if (responseData.answers && responseData.answers[index] !== undefined) {
          delete responseData.answers[index];
          batch.update(doc(db, 'responses', docSnapshot.id), { answers: responseData.answers });
        }
      });

      await batch.commit();
    } catch (error) {
      console.error('Error deleting responses linked to the question:', error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-2xl p-6 bg-white rounded-lg shadow-lg m-3">
        <h2 className="text-3xl font-bold mb-4 text-center">Edit Form</h2>
        <div className="mb-4">
            <label htmlFor="colorPicker" className="block font-medium text-gray-700 mb-2">
              Choose a color:
            </label>
            <div className="flex items-center space-x-2">
              <input
                type="color"
                id="colorPicker"
                name="colorPicker"
                value={colorTheme}
                onChange={handleColorChange}
                className="w-14 h-10 border-none cursor-pointer"
              />
              <span id="colorValue" className="text-gray-800 font-semibold">
                {colorTheme}
              </span>
            </div>
            <input
                type="text"
                placeholder="Form Name"
                value={formName}
                onChange={(e) => setFormName(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-3"
            />
        </div>
        <h3 className="text-2xl font-bold mb-4">Questions</h3>
        {questions.map((question, index) => (
          <div key={index} className="mb-4">
            <div className="flex items-center mb-2">
              <input
                type="text"
                value={question.text}
                onChange={(e) => handleQuestionChange(index, e.target.value)}
                className="w-full border border-gray-300 rounded-md p-3"
              />
              <select
                value={question.type}
                onChange={(e) => handleQuestionTypeChange(index, e.target.value)}
                className="ml-2 border border-gray-300 rounded-md p-3"
              >
                <option value="text">Text</option>
                <option value="rating">Rating</option>
                <option value="checkbox">Checkbox</option>
              </select>
              <button
                onClick={() => handleDeleteQuestion(index)}
                className="text-red-600 ml-2"
              >
                Delete
              </button>
            </div>
            {question.type === 'checkbox' && (
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2">Options</label>
                {question.options.map((option, oIndex) => (
                  <div key={oIndex} className="mb-2 flex items-center">
                    <input
                      type="text"
                      value={option}
                      onChange={(e) => handleOptionChange(index, oIndex, e.target.value)}
                      className="w-full border border-gray-300 rounded-md p-3"
                      placeholder={`Option ${oIndex + 1}`}
                    />
                    {oIndex > 0 && (
                      <button
                        onClick={() => handleDeleteOption(index, oIndex)}
                        className="ml-2 bg-red-600 text-white px-2 py-1 rounded-md shadow-md hover:bg-red-700 transition duration-300"
                      >
                        Delete
                      </button>
                    )}
                  </div>
                ))}
                <button
                  onClick={() => handleAddOption(index)}
                  className="w-full py-2 px-4 bg-white border-dotted border-2 text-gray-500 rounded-md shadow-md hover:bg-gray-300 transition duration-300"
                >
                  + Add Option
                </button>
              </div>
            )}
            {question.type === 'rating' && (
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">Rating Labels</label>
                    <div className="">
                    <label>Label for 1: </label>
                    <br></br>
                    <input
                        type="text"
                        value={question.label1 || ''}
                        onChange={(e) => handleLabelChange(index, 'label1', e.target.value)}
                        className="w-full px-3 py-2 mb-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Label for 1"
                    />
                    <label>Label for 5: </label>
                    <input
                        type="text"
                        value={question.label5 || ''}
                        onChange={(e) => handleLabelChange(index, 'label5', e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Label for 5"
                    />
                    </div>
                </div>
            )}
          </div>
            
        ))}
        <button
          onClick={handleAddQuestion}
          className="bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 transition-colors duration-300"
        >
          Add Question
        </button>
        <div className="flex justify-between mt-4">
          <button
            onClick={() => navigate('/admin')}
            className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700 transition-colors duration-300"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditForm;

