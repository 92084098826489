import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs, where, query, doc, getDoc } from 'firebase/firestore';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Legend, PieChart, Pie, Cell } from 'recharts';
import Sentiment from 'sentiment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faHandSparkles } from '@fortawesome/free-solid-svg-icons';
import { HfInference } from '@huggingface/inference';

// Initialize the Hugging Face Inference client
const inference = new HfInference("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"); // Replace with your API key

const ResultsDisplay = () => {
  const { formId } = useParams();
  const [formResponses, setFormResponses] = useState([]);
  const [formName, setFormName] = useState('');
  const [summary, setSummary] = useState([]);
  const [loadingResponses, setLoadingResponses] = useState(true);
  const [loadingSummary, setLoadingSummary] = useState(true);
  const [questions, setQuestions] = useState({});
  const sentiment = new Sentiment();
  const [viewMode, setViewMode] = useState('all');
  const [currentResponseIndex, setCurrentResponseIndex] = useState(0);
  const [aiSummary, setAISummary] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchFormName();
    fetchFormResponses();
  }, [formId]);

  const fetchFormName = async () => {
    try {
      const formDocRef = doc(db, 'forms', formId);
      const formDoc = await getDoc(formDocRef);
      if (formDoc.exists()) {
        const formName = formDoc.data().formName;
        setFormName(capitalizeFirstLetter(formName));
      } else {
        console.error('No such form document!');
      }
    } catch (error) {
      console.error('Error fetching form name:', error);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const fetchFormResponses = async () => {
    setLoadingResponses(true);
    try {
      const responsesRef = collection(db, 'responses');
      const q = query(responsesRef, where('formId', '==', formId));
      const querySnapshot = await getDocs(q);
      const responses = querySnapshot.docs.map(doc => ({
        id: doc.id,
        answers: doc.data().answers
      }));

      const responsesWithQuestions = await Promise.all(
        responses.map(async (response) => {
          const answersWithQuestions = await Promise.all(
            Object.keys(response.answers).map(async (questionId) => ({
              question: await fetchQuestionText(formId, questionId),
              answer: response.answers[questionId]
            }))
          );
          return { ...response, answers: answersWithQuestions };
        })
      );

      setFormResponses(responsesWithQuestions);
      generateSummary(responsesWithQuestions);
    } catch (error) {
      console.error('Error fetching form responses:', error);
    }
    setLoadingResponses(false);
  };

  const fetchQuestionText = async (formId, questionId) => {
    try {
      const formDocRef = doc(db, 'forms', formId);
      const formDoc = await getDoc(formDocRef);
      
      if (formDoc.exists()) {
        const questions = formDoc.data().questions;
        setQuestions(questions);
        const questionText = questions[questionId]?.text || 'Question not found';
        return questionText;
      } else {
        throw new Error('Form document not found');
      }
    } catch (error) {
      console.error('Error fetching question text:', error);
      return 'Question not found';
    }
  };
  
  const categorizeAnswers = (answers) => {
    const categorizedData = {};

    answers.forEach(({ question, answer }) => {
      if (!categorizedData[question]) {
        categorizedData[question] = [];
      }
      categorizedData[question].push(answer);
    });

    return categorizedData;
  };

  const renderGraph = (question, data) => {
    const colorPalette = ['#82ca9d', '#8884d8', '#ffc658', '#ff7f50', '#ffbb28']; // Add more colors as needed
  
    if (data.every(answer => !isNaN(answer))) {
      // Numeric data handling
      const chartData = data.map((answer, index) => ({
        name: `Response ${index + 1}`,
        value: Number(answer),
      }));
      const average = (data.reduce((acc, val) => acc + Number(val), 0) / data.length).toFixed(2);
      const overallSentiment = performSentimentAnalysisByAverage(average);
  
      return (
        <div>
          <h4 className="text-center font-bold">
            Average: {average} <span className={`ml-2 ${getSentimentColor(overallSentiment)}`}>{overallSentiment}</span>
          </h4>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
    } else if (data.some(Array.isArray)) {
      // Checkbox data handling
      const optionCounts = {};
      data.forEach(answer => {
        if (Array.isArray(answer)) {
          answer.forEach(option => {
            optionCounts[option] = (optionCounts[option] || 0) + 1;
          });
        }
      });
  
      const chartData = Object.entries(optionCounts).map(([option, count], index) => ({
        name: option,
        value: count,
        fill: colorPalette[index % colorPalette.length], // Assign color based on index
      }));
  
      return (
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              label
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.fill} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      );
    }
  
    return null;
  };
  

  const performSentimentAnalysisByAverage = (average) => {
    const score = parseFloat(average);
    if (score > 3) return 'positive';
    if (score < 3) return 'negative';
    return 'neutral';
  };

  const getSentimentColor = (sentiment) => {
    if (sentiment === 'positive') return 'text-green-500';
    if (sentiment === 'negative') return 'text-red-500';
    return 'text-yellow-500';
  };

  const generateSummary = async (responses) => {

    if (viewMode !== 'all') {
      setSummary([]);
      return; // Skip summary generation if not viewing 'all'
    }

    setLoadingSummary(true);
    const summaryData = [];
    const categorizedData = categorizeAnswers(responses.flatMap(response => response.answers));
  
    const unwantedWords = [
      "the", "is", "in", "and", "to", "of", "a", "for", "on", "that", "can", "they", "their", "them", "try", "be", "you", "i", "think"
    ];
  
    for (const [question, data] of Object.entries(categorizedData)) {
      const sentimentSummary = { positive: 0, neutral: 0, negative: 0 };
      const wordFrequency = {};
      const totalResponses = data.length;
  
      const questionDetails = await fetchQuestionDetails(formId, question);
  
      if (questionDetails) {
        if (questionDetails.type === 'yes_no') {
          const yesCount = data.filter(answer => answer.toLowerCase() === 'yes').length;
          const noCount = data.filter(answer => answer.toLowerCase() === 'no').length;
          const yesPercentage = ((yesCount / totalResponses) * 100).toFixed(2);
          const noPercentage = ((noCount / totalResponses) * 100).toFixed(2);
  
          summaryData.push({
            question,
            summary: {
              yesCount,
              noCount,
              yesPercentage,
              noPercentage,
            },
          });
          continue; // Skip further processing for yes/no questions
        }
  
        if (questionDetails.type === 'checkbox') {
          const optionCounts = {};
          let totalSelections = 0;
        
          data.forEach((answer) => {
            if (Array.isArray(answer)) {
              answer.forEach((option) => {
                optionCounts[option] = (optionCounts[option] || 0) + 1;
                totalSelections++;
              });
            }
          });
        
          // Calculate percentages
          const optionPercentages = {};
          for (const option in optionCounts) {
            optionPercentages[option] = ((optionCounts[option] / totalSelections) * 100).toFixed(2) + '%';
          }
        
          summaryData.push({
            question,
            summary: { options: optionPercentages }
          });
          
          continue; // Skip further processing for checkbox questions
        }
        
  
        if (questionDetails.type !== 'text') {
          const numericData = data.filter(answer => !isNaN(answer));
          if (numericData.length > 0) {
            const average = (numericData.reduce((acc, val) => acc + Number(val), 0) / numericData.length).toFixed(2);
            const overallSentiment = performSentimentAnalysisByAverage(average);
            summaryData.push({
              question,
              summary: { average, sentiment: overallSentiment },
            });
          } else {
            summaryData.push({
              question,
              summary: { average: 'N/A', sentiment: 'neutral' },
            });
          }
          continue;
        }
  
        data.forEach((answer) => {
          if (typeof answer === 'string') {
            const sentimentScore = performSentimentAnalysis(answer);
            sentimentSummary[sentimentScore] += 1;
            const words = answer.split(/\s+/);
            words.forEach((word) => {
              const cleanedWord = word.toLowerCase().replace(/[.,!?]/g, '');
              if (cleanedWord && !unwantedWords.includes(cleanedWord) && isNaN(cleanedWord)) {
                wordFrequency[cleanedWord] = (wordFrequency[cleanedWord] || 0) + 1;
              }
            });
          }
        });
  
        const importantWords = Object.entries(wordFrequency)
          .sort(([, a], [, b]) => b - a)
          .slice(0, 10)
          .map(([word, count]) => ({
            word,
            percentage: ((count / totalResponses) * 100).toFixed(2),
          }));
  
        const highlightedResponses = data.map((answer) => {
          if (typeof answer === 'string') {
            importantWords.forEach(({ word }) => {
              const regex = new RegExp(`\\b(${word})\\b`, 'gi');
              answer = answer.replace(regex, '<mark>$1</mark>');
            });
            return answer;
          }
          return null;
        }).filter(response => response !== null);
  
        summaryData.push({
          question,
          summary: {
            importantWords,
            highlightedResponses,
            initialResponses: data,
            sentimentSummary,
          },
        });
      } else {
        console.error(`Question details not found for ID: ${question}`);
      }
    }
  
    setSummary(summaryData);
    setLoadingSummary(false);
  };
  
  
  
  const fetchQuestionDetails = async (formId, questionId) => {
    try {
      const formDocRef = doc(db, 'forms', formId);
      const formDoc = await getDoc(formDocRef);
      
      if (formDoc.exists()) {
        const questions = formDoc.data().questions;
        const questionDetails = questions.find(q => q.text === questionId);
        if (questionDetails) {
          return questionDetails;
        } else {
          console.error(`No details found for question ID: ${questionId}`);
          return null;
        }
      } else {
        throw new Error('Form document not found');
      }
    } catch (error) {
      console.error('Error fetching question details:', error);
      return null;
    }
  };

  const performSentimentAnalysis = (answer) => {
    const { score } = sentiment.analyze(answer);
    if (score > 0) return 'positive';
    if (score < 0) return 'negative';
    return 'neutral';
  };

  const generateAISummary = async (inputs) => {
    setLoading(true);
    console.log(inputs)
    const messages = [
      {
        role: "user",
        content: `These opinions are from different people and I want you to make me a 20 words, one sentence summary of the main and frequent points made by these different people from: ${inputs.filter(input => input.trim() !== '').join(' ')}`
      }
    ];

    try {
      let summaryText = '';
      for await (const chunk of inference.chatCompletionStream({
        model: "meta-llama/Meta-Llama-3-8B-Instruct",
        messages: messages,
        max_tokens: 500,
      })) {
        summaryText += chunk.choices[0]?.delta?.content || "";
      }

      setAISummary(summaryText);
    } catch (error) {
      console.error('Error generating summary:', error);
      setAISummary('Error generating summary.');
    } finally {
      setLoading(false);
    }
  };

  const handlePreviousResponse = () => {
    setCurrentResponseIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : formResponses.length - 1));
  };

  const handleNextResponse = () => {
    setCurrentResponseIndex(prevIndex => (prevIndex < formResponses.length - 1 ? prevIndex + 1 : 0));
  };

  const toggleViewMode = () => {
    setViewMode(prevMode => (prevMode === 'all' ? 'single' : 'all'));
  };


  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
    <div className="container mx-auto">
      <h2 className="text-3xl font-bold mb-4 text-center">{formName} Results</h2>
      <div className="flex justify-between items-center mb-4">
        <button onClick={toggleViewMode} className="bg-gray-200 p-2 rounded">
          {viewMode === 'all' ? 'View Single Response' : 'View All Responses'}
        </button>
      </div>
      {loadingResponses ? (
        <p className="text-center">Loading responses...</p>
      ) : formResponses.length > 0 ? (
        <>
          {viewMode === 'all' ? (
            <ul className="space-y-4">
              {Object.entries(categorizeAnswers(formResponses.flatMap(response => response.answers))).map(([question, data], index) => (
                <li key={index} className="bg-white shadow-md rounded-lg p-4">
                  <h3 className="text-xl font-bold mb-2">{question}</h3>
                  {renderGraph(question, data)}
                  {data.some(answer => isNaN(answer)) && questions[index]?.type !== 'checkbox' && (
                    <div className="mt-4">
                      {data.filter(answer => isNaN(answer)).map((answer, idx) => (
                        <div key={idx} className="border p-2 mb-2">
                          <strong>Response:</strong> {answer}
                        </div>
                      ))}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <div>
              <div className="flex justify-between items-center mb-4">
                <button onClick={handlePreviousResponse} className="bg-gray-200 p-2 rounded">
                  <FontAwesomeIcon icon={faArrowLeft} /> Previous
                </button>
                <h3 className="text-xl font-bold">Response {currentResponseIndex + 1} of {formResponses.length}</h3>
                <button onClick={handleNextResponse} className="bg-gray-200 p-2 rounded">
                  Next <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
              {formResponses[currentResponseIndex].answers.map(({ question, answer }) => (
                <div key={question} className="bg-white shadow-md rounded-lg p-4 mb-4">
                  <h4 className="font-bold">{question}</h4>
                  <p>{Array.isArray(answer) ? (answer.length === 1 ? answer[0] : answer.join(', ')) : answer}</p>
                </div>
              ))}
            </div>
          )}
          {viewMode === 'all' && (
          <div className="mt-8">
            <h3 className="text-2xl font-bold mb-4">Summary</h3>
            {loadingSummary ? (
              <p>Loading summary...</p>
            ) : (
              <ul className="space-y-4">
                {summary.map((item, index) => (
                  <li key={index} className="bg-white shadow-md rounded-lg p-4">
                    <h4 className="text-lg font-semibold">{item.question}</h4>
                    {item.summary.yesCount !== undefined && (
                      <div className="mt-4">
                        <h5 className="font-semibold">Responses:</h5>
                        <span>Yes: {item.summary.yesCount} ({item.summary.yesPercentage}%)</span>
                        <span className="ml-4">No: {item.summary.noCount} ({item.summary.noPercentage}%)</span>
                      </div>
                    )}
                    {item.summary.options && (
                      <div className="mt-4">
                        <h5 className="font-semibold">Responses:</h5>
                        <ul className="space-y-2">
                          {Object.entries(item.summary.options).map(([option, percentage], idx) => (
                            <li key={idx} className="border border-gray-200 p-2 rounded">
                              {option}: {percentage}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {item.summary.importantWords && item.summary.importantWords.length > 0 && (
                      <div>
                        <h5 className="font-semibold">Important Words:</h5>
                        <table className="min-w-full border-collapse border border-gray-200">
                          <thead>
                            <tr>
                              <th className="border border-gray-300 p-2">Word</th>
                              <th className="border border-gray-300 p-2">Percentage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.summary.importantWords.map((word, idx) => (
                              <tr key={idx}>
                                <td className="border border-gray-300 p-2">{word.word}</td>
                                <td className="border border-gray-300 p-2">{word.percentage}%</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        
                      </div>
                    )}
                    {item.summary.highlightedResponses && item.summary.highlightedResponses.length > 0 && (
                      <div className="mt-4">
                        <h5 className="font-semibold">Highlighted Responses:</h5>
                        <ul className="space-y-2">
                          {item.summary.highlightedResponses.map((response, idx) => (
                            <li
                              key={idx}
                              className="border border-gray-200 p-2 rounded"
                              dangerouslySetInnerHTML={{ __html: response }}
                            />
                          ))}
                        </ul>
                        <button className='px-4 py-2 bg-primary text-white rounded-md shadow-md mt-2' disabled={loading} onClick={() => generateAISummary(item.summary.initialResponses)}>{loading ? 'Generating...' : <><FontAwesomeIcon className='mr-2' icon={faHandSparkles} />Generate Summary</>}</button>
                        { aiSummary !== '' && (
                        <div>
                          <h3 className='font-semibold'>General Summary:</h3>
                          <p>{aiSummary}</p>
                        </div>
                        )}
                      </div>
                    )}
                    {item.summary.average && (
                      <div className="mt-4">
                        <h5 className="font-semibold">Average Rating:</h5>
                        <span>{item.summary.average}</span>
                        <span className={`ml-2 ${getSentimentColor(performSentimentAnalysisByAverage(item.summary.average))}`}>
                          ({performSentimentAnalysisByAverage(item.summary.average)})
                        </span>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
          )}
        </>
      ) : (
        <p className="text-center">No responses found.</p>
      )}
    </div>
  </div>
  );
};

export default ResultsDisplay;


