import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase'; // Import Firebase Firestore instance

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // State to hold current user data
  const [loading, setLoading] = useState(true);
  const [forms, setForms] = useState([]); // State to hold user's forms

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async (authUser) => {
      if (authUser) {
        // Fetch user-specific data (e.g., forms)
        await fetchUserForms(authUser.uid);
      }
      setUser(authUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const fetchUserForms = async (userId) => {
    try {
      const formsRef = db.collection('forms').where('createdBy', '==', userId);
      const querySnapshot = await formsRef.get();
      const fetchedForms = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setForms(fetchedForms);
    } catch (error) {
      console.error('Error fetching user forms:', error);
    }
  };

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(getAuth(), email, password);
    } catch (error) {
      console.error('Login error:', error);
      alert('Invalid credentials');
    }
  };

  const logout = async () => {
    try {
      await signOut(getAuth());
      setUser(null); // Clear user state on logout
      setForms([]);  // Clear forms state on logout
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, forms, isAuthenticated: !!user, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

