import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, doc, getDoc, addDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

const UserFormDisplay = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [formTitle, setFormTitle] = useState('');
  const [colorTheme, setColorTheme] = useState('');
  const { id } = useParams();

  useEffect(() => {
    fetchFormQuestions(id);
  }, [id]);

  const fetchFormQuestions = async (id) => {
    try {
      const formRef = doc(collection(db, 'forms'), id);
      const formDoc = await getDoc(formRef);
      if (formDoc.exists()) {
        const formData = formDoc.data();
        if (formData.questions) {
          setFormTitle(formData.formName);
          setQuestions(formData.questions);
          setColorTheme(formData.colorTheme);
        }
      }
    } catch (error) {
      console.error('Error fetching form questions:', error);
    }
  };

  const handleInputChange = (index, value) => {
    setAnswers({
      ...answers,
      [index]: value,
    });
  };

  const handleCheckboxChange = (index, option) => {
    const existingAnswers = answers[index] || [];
    if (existingAnswers.includes(option)) {
      setAnswers({
        ...answers,
        [index]: existingAnswers.filter((item) => item !== option),
      });
    } else {
      setAnswers({
        ...answers,
        [index]: [...existingAnswers, option],
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await addDoc(collection(db, 'responses'), {
        formId: id,
        answers,
      });
      alert('Your answers have been submitted successfully!');
    } catch (error) {
      console.error('Error submitting answers:', error);
      alert('There was an error submitting your answers. Please try again.');
    }
  };

  return (
    <div className="min-h-screen w-full flex items-center justify-center" style={{backgroundColor: colorTheme}}>
      <div className="w-full max-w-2xl p-4 flex items-center flex-col bg-white rounded-lg shadow-lg m-4">
        <h2 className="text-3xl font-bold mb-4 text-center" style={{color: colorTheme}}>{formTitle}</h2>
        <form onSubmit={handleSubmit}>
          {questions.map((question, index) => (
            <div key={index} className="mb-6">
              <p className="font-medium text-gray-700 mb-2 text-center">{question.text}</p>
              {question.type === 'text' ? (
                <textarea
                  className="w-full border border-gray-300 rounded-md p-3"
                  rows="4"
                  placeholder="Type your answer here..."
                  onChange={(e) => handleInputChange(index, e.target.value)}
                ></textarea>
              ) : question.type === 'rating' ? (
                <div>
                  
                  <div className="flex flex-col xs:flex-col sm:flex-row sm:space-x-2 lg:space-x-4 space-y-2 sm:space-y-0">
                    <span>{question.label1 || '1'}</span>
                    {[1, 2, 3, 4, 5].map((star) => (
                      <label key={star} className="flex items-center space-x-2">
                        <span>{star}</span>
                        <input
                          type="radio"
                          name={`question-${index}`}
                          value={star}
                          className="radio text-blue-600"
                          onChange={() => handleInputChange(index, star)}
                        />
                      </label>
                    ))}
                    <span>{question.label5 || '5'}</span>
                  </div>
                </div>
              ) : question.type === 'checkbox' ? (
                <div className="flex flex-col space-y-2">
                  {question.options.map((option, oIndex) => (
                    <label key={oIndex} className="flex items-center">
                      <input
                        type="checkbox"
                        value={option}
                        className="checkbox text-blue-600"
                        onChange={() => handleCheckboxChange(index, option)}
                        checked={answers[index]?.includes(option) || false}
                      />
                      <span className="ml-2">{option}</span>
                    </label>
                  ))}
                </div>
              ) : null}
            </div>
          ))}
          <div className="flex justify-center">
            <button
              type="submit"
              className="text-white py-2 px-6 rounded-lg transition-colors duration-300"
              style={{backgroundColor: colorTheme}}
            >
              Submit Answers
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserFormDisplay;
