import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { HfInference } from '@huggingface/inference';

// Initialize the Hugging Face Inference client
const inference = new HfInference("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"); // Replace with your API key


const apiKey = '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'; // Replace with your API key


const TransformerForm = () => {
    const [inputs, setInputs] = useState(['']);
    const [summary, setSummary] = useState('');
    const [loading, setLoading] = useState(false);
  
    // Function to handle adding a new input box
    const addInputBox = () => {
      setInputs([...inputs, '']);
    };
  
    // Function to handle input change
    const handleInputChange = (index, event) => {
      const newInputs = [...inputs];
      newInputs[index] = event.target.value;
      setInputs(newInputs);
    };
  
    const generateSummary = async () => {
      setLoading(true);
      const messages = [
        {
          role: "user",
          content: `These are points made by different people, I want you to provide me with a brief 20 word summary of the main points from: ${inputs.filter(input => input.trim() !== '').join(' ')}`
        }
      ];
  
      try {
        let summaryText = '';
        for await (const chunk of inference.chatCompletionStream({
          model: "meta-llama/Meta-Llama-3-8B-Instruct",
          messages: messages,
          max_tokens: 500,
        })) {
          summaryText += chunk.choices[0]?.delta?.content || "";
        }
  
        setSummary(summaryText);
      } catch (error) {
        console.error('Error generating summary:', error);
        setSummary('Error generating summary.');
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <div>
        {inputs.map((input, index) => (
          <div key={index}>
            <textarea
              value={input}
              onChange={(e) => handleInputChange(index, e)}
              placeholder={`Enter input ${index + 1}`}
              rows="3"
              cols="60"
            />
          </div>
        ))}
        <button onClick={addInputBox}>
          Add More Inputs
        </button>
        <button onClick={generateSummary} disabled={loading}>
          {loading ? 'Generating...' : 'Generate Summary'}
        </button>
        <div>
          <h3>Summary:</h3>
          <p>{summary}</p>
        </div>
      </div>
    );
};

export default TransformerForm;
