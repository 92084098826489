import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import 'tailwindcss/tailwind.css';

const AdminFormCreation = () => {
  const { user } = useAuth();
  const [colorTheme, setColorTheme] = useState('#ff0000');
  const [currentForm, setCurrentForm] = useState({ questions: [], formName: '' });
  const [questionText, setQuestionText] = useState('');
  const [questionType, setQuestionType] = useState('rating');
  const [options, setOptions] = useState(['']);
  const [label1, setLabel1] = useState('');
  const [label5, setLabel5] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const formName = searchParams.get('formName');
    if (formName) {
      setCurrentForm({ ...currentForm, formName });
    }
  }, [searchParams]);

  const handleAddOption = () => {
    setOptions([...options, '']);
  };

  const handleColorChange = (event) => {
    setColorTheme(event.target.value);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleAddQuestion = () => {
    if (questionText.trim() !== '' && currentForm.questions.length < 10) {
      const newQuestion = { text: questionText, type: questionType };
      if (questionType === 'checkbox') {
        newQuestion.options = options.filter(option => option.trim() !== '');
      } else if (questionType === 'rating') {
        newQuestion.label1 = label1;
        newQuestion.label5 = label5;
      }
      setCurrentForm({
        ...currentForm,
        questions: [...currentForm.questions, newQuestion],
        colorTheme: colorTheme,
      });
      setQuestionText('');
      setOptions(['']);
      setLabel1('');
      setLabel5('');
    }
  };

  const handleDeleteQuestion = (index) => {
    setCurrentForm({
      ...currentForm,
      questions: currentForm.questions.filter((_, i) => i !== index),
    });
  };

  const handleSaveForm = async () => {
    try {
      if (currentForm.formName.trim() !== '' && currentForm.questions.length > 0) {
        const formToAdd = {
          ...currentForm,
          createdBy: user.uid,
        };
        const docRef = await addDoc(collection(db, 'forms'), formToAdd);
        setCurrentForm({ questions: [], formName: '', colorTheme: colorTheme });
        console.log('Form added with ID: ', docRef.id);
        navigate('/admin');
      }
    } catch (error) {
      console.error('Error saving form:', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="w-full max-w-lg p-6 bg-white rounded-lg shadow-lg mb-4">
        <h2 className="text-2xl font-semibold mb-4 text-center">Create a Review Form</h2>
        <div className="mb-4">
            <label htmlFor="colorPicker" className="block font-medium text-gray-700 mb-2">
              Choose a color:
            </label>
            <div className="flex items-center space-x-2">
              <input
                type="color"
                id="colorPicker"
                name="colorPicker"
                value={colorTheme}
                onChange={handleColorChange}
                className="w-14 h-10 border-none cursor-pointer"
              />
              <span id="colorValue" className="text-gray-800 font-semibold">
                {colorTheme}
              </span>
            </div>
          <label className="block text-gray-700 font-medium mb-2">Question</label>
          <input
            type="text"
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter your question"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Question Type</label>
          <select
            value={questionType}
            onChange={(e) => setQuestionType(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="rating">Rating</option>
            <option value="text">Text</option>
            <option value="checkbox">Checkbox</option>
          </select>
        </div>
        {questionType === 'rating' && (
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Rating Labels</label>
            <div className="">
              <label>Label for 1: </label>
              <input
                type="text"
                value={label1}
                onChange={(e) => setLabel1(e.target.value)}
                className="w-full px-3 py-2 mb-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Label for 1"
              />
              <label>Label for 5: </label>

              <input
                type="text"
                value={label5}
                onChange={(e) => setLabel5(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Label for 5"
              />
            </div>
          </div>
        )}
        {questionType === 'checkbox' && (
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Options</label>
            {options.map((option, index) => (
              <div key={index} className="mb-2 flex items-center">
                <input
                  type="text"
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder={`Option ${index + 1}`}
                />
                {index > 0 && (
                  <button
                    onClick={() => setOptions(options.filter((_, i) => i !== index))}
                    className="ml-2 bg-red-600 text-white px-2 py-1 rounded-md shadow-md hover:bg-red-700 transition duration-300"
                  >
                    Delete
                  </button>
                )}
              </div>
            ))}
            <button
              onClick={handleAddOption}
              className="w-full py-2 px-4 bg-white text-gray-500 border-dotted border-2 rounded-md shadow-md hover:bg-gray-300 transition duration-300"
            >
              + Add Option
            </button>
          </div>
        )}
        <button
          onClick={handleAddQuestion}
          className="w-full py-2 px-4 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 transition duration-300 mb-4"
        >
          Add Question
        </button>
        <div className="mb-4">
          <h3 className="text-xl font-semibold mb-2">Questions:</h3>
          <ul className="list-disc pl-5">
            {currentForm.questions.map((q, index) => (
              <li key={index} className="mb-2 flex justify-between items-center">
                <span>
                  <span className="font-medium">{q.text}</span> ({q.type})
                  {q.type === 'rating' && (
                    <span> [1: {q.label1}, 5: {q.label5}]</span>
                  )}
                  {q.type === 'checkbox' && (
                    <ul className="list-disc pl-5 mt-1">
                      {q.options.map((option, i) => (
                        <li key={i}>{option}</li>
                      ))}
                    </ul>
                  )}
                </span>
                <button
                  onClick={() => handleDeleteQuestion(index)}
                  className="bg-red-600 text-white px-2 py-1 rounded-md shadow-md hover:bg-red-700 transition duration-300"
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>
        <button
          onClick={handleSaveForm}
          className="w-full py-2 px-4 bg-green-600 text-white rounded-md shadow-md hover:bg-green-700 transition duration-300"
        >
          Save Form
        </button>
      </div>
    </div>
  );
};

export default AdminFormCreation;

