import { faChartBar, faFile, faFileAlt, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';

const LandingPage = () => {

    const features = [
        {
            icon:
                <FontAwesomeIcon icon={faMessage} />,
            title: "Easy Feedback Collection",
            desc: "Collect feedback effortlessly through our user-friendly interface."
        },
        {
            icon:
                <FontAwesomeIcon icon={faChartBar} />,
            title: "Real-time Analytics",
            desc: "Get insights and analytics in real-time to make informed decisions."
        },
        {
            icon:
                <FontAwesomeIcon icon={faFileAlt} />,
            title: "Customizable Surveys",
            desc: "Create surveys that fit your business needs and requirements."
        }
    ]

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_rwb4x5w', 'template_7gbk68c', e.target, 'BfJat4W3gqu_GryYn')
          .then((result) => {
              console.log(result.text);
              alert('Message sent successfully!');
          }, (error) => {
              console.log(error.text);
              alert('Failed to send message, please try again.');
          });
    
        e.target.reset();
    };

    return (
        <div className="bg-gray-100 min-h-screen flex flex-col">
            <header className="bg-white shadow">
                <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
                    
                    <h1 className="text-3xl font-bold text-gray-900">Evalspot</h1>
                    <nav>
                        <a href="#features" className="text-gray-900 px-3 py-2 rounded-md text-sm">Features</a>
                        {/* <a href="#pricing" className="text-gray-900 px-3 py-2 rounded-md text-sm font-medium">Pricing</a> */}
                        <a href="#contact" className="text-gray-900 px-3 py-2 rounded-md text-sm">Contact</a>
                        <Link to={"/login"} className="text-gray-900 px-3 py-2 rounded-md text-sm font-medium bg-primary text-white ml-2">Sign In</Link>
                    </nav>
                </div>
            </header>

            <main className="flex-grow">
                <section>
                    <div className="max-w-screen-xl mx-auto px-12 py-28 gap-12 text-gray-600 overflow-hidden md:flex">
                        <div className='flex-none space-y-5 max-w-xl'>
                            {/* <a href="javascript:void(0)" className='inline-flex gap-x-6 items-center rounded-full p-1 pr-6 border text-sm font-medium duration-150 hover:bg-white'>
                                <span className='inline-block rounded-full px-3 py-1 bg-indigo-600 text-white'>
                                    News
                                </span>
                                <p className='flex items-center'>
                                    Read the launch post from here
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                    </svg>
                                </p>
                            </a> */}
                            <h1 className="text-4xl text-gray-800 font-extrabold sm:text-5xl">
                                Get Valuable Feedback from Customers
                            </h1>
                            <p>
                                Feedback is one of the most important aspects of starting a business and evaluating that feedback is the key to better business.
                            </p>
                            <div className='flex items-center gap-x-3 sm:text-sm'>
                                <a href="#contact" className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-gray-800 duration-150 hover:bg-gray-700 active:bg-gray-900 rounded-full md:inline-flex">
                                    Get started
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                    </svg>
                                </a>
                                {/* <a href="javascript:void(0)" className="flex items-center justify-center gap-x-1 py-2 px-4 text-gray-700 hover:text-gray-900 font-medium duration-150 md:inline-flex">
                                    Contact sales
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                    </svg>
                                </a> */}
                            </div>
                        </div>
                        <div className='flex-1 hidden md:block'>
                            {/* Replace with your image */}
                            <img src="https://raw.githubusercontent.com/sidiDev/remote-assets/c86a7ae02ac188442548f510b5393c04140515d7/undraw_progressive_app_m-9-ms_oftfv5.svg" className="max-w-xl" />
                        </div>
                    </div>
                </section>

                <section className="py-14" id="features">
                    <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                        <div className="max-w-xl space-y-3">
                            <h3 className="text-secondary font-semibold">
                                Features
                            </h3>
                            <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                                What we provide
                            </p>
                            <p>
                                Simple to use but extremely effective for collecting advice.
                            </p>
                        </div>
                        <div className="mt-12">
                            <ul className="grid gap-x-12 divide-y [&>.feature-1]:pl-0 sm:grid-cols-2 sm:gap-y-8 sm:divide-y-0 lg:divide-x lg:grid-cols-3 lg:gap-x-0">
                                {
                                    features.map((item, idx) => (
                                        <li key={idx} className={`feature-${idx + 1} space-y-3 py-8 lg:px-12 sm:py-0`}>
                                            <div className="w-12 h-12 border text-primary rounded-full flex items-center justify-center">
                                                {item.icon}
                                            </div>
                                            <h4 className="text-lg text-gray-800 font-semibold">
                                                {item.title}
                                            </h4>
                                            <p>
                                                {item.desc}
                                            </p>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </section>

            {/* <section id="pricing" className="bg-gray-200 py-20">
            <div className="max-w-7xl mx-auto text-center">
                <h2 className="text-3xl font-bold mb-4">Pricing</h2>
                <p className="text-xl mb-8">Choose a plan that works for you.</p>
                <div className="flex flex-wrap justify-center space-x-6">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-xs mb-4">
                    <h3 className="text-2xl font-bold mb-2">Basic</h3>
                    <p className="text-gray-700 mb-4">$9/month</p>
                    <p className="text-gray-700">Basic features for small businesses.</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-xs mb-4">
                    <h3 className="text-2xl font-bold mb-2">Pro</h3>
                    <p className="text-gray-700 mb-4">$29/month</p>
                    <p className="text-gray-700">Advanced features for growing businesses.</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-xs mb-4">
                    <h3 className="text-2xl font-bold mb-2">Enterprise</h3>
                    <p className="text-gray-700 mb-4">$99/month</p>
                    <p className="text-gray-700">All features for large enterprises.</p>
                </div>
                </div>
            </div>
            </section> */}

            <section id="contact" className="py-20">
                <div className="max-w-7xl mx-auto text-center">
                    <h2 className="text-3xl font-bold mb-4">Contact Us</h2>
                    <p className="text-xl mb-8">Want to register now? We're here to help.</p>
                    <form onSubmit={sendEmail} className="max-w-lg mx-auto">
                    <input type="text" name="user_name" placeholder="Name" className="w-full mb-4 px-4 py-2 rounded-lg border border-gray-300" required />
                    <input type="email" name="user_email" placeholder="Email" className="w-full mb-4 px-4 py-2 rounded-lg border border-gray-300" required />
                    <textarea name="message" placeholder="Message" className="w-full mb-4 px-4 py-2 rounded-lg border border-gray-300" rows="5" required></textarea>
                    <button type="submit" className="bg-primary text-white px-6 py-3 rounded-full font-semibold">Send Message</button>
                    </form>
                </div>
            </section>
        </main>

        <footer className="bg-gray-800 text-white py-6">
            <div className="max-w-7xl mx-auto text-center">
            <p>&copy; 2024 Evalspot. All rights reserved.</p>
            </div>
        </footer>
        </div>
    );
}

export default LandingPage;
