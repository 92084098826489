import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, where, doc, deleteDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

const AdminDashboard = () => {
  const { user, isAuthenticated } = useAuth();
  const [forms, setForms] = useState([]);
  const navigate = useNavigate();
  const [formName, setFormName] = useState('');
  const [formIdToSend, setFormIdToSend] = useState('');
  const [showQRModal, setShowQRModal] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      fetchAdminForms();
    }
  }, [isAuthenticated]);

  const fetchAdminForms = async () => {
    try {
      const formsRef = collection(db, 'forms');
      const querySnapshot = await getDocs(query(formsRef, where('createdBy', '==', user.uid)));
      const fetchedForms = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setForms(fetchedForms);
    } catch (error) {
      console.error('Error fetching forms:', error);
    }
  };

  const handleCreateForm = () => {
    if (formName === '') {
      alert("Give your form a name.");
    } else {
      navigate(`/create-form?formName=${encodeURIComponent(formName)}`);
    }
  };

  const handleEditForm = (formId) => {
    navigate(`/edit-form/${formId}`);
  };

  const handleViewResults = (formId) => {
    navigate(`/results/${formId}`);
  };

  const handleCopyLink = () => {
    const link = `${window.location.origin}/form/${formIdToSend}`;
    navigator.clipboard.writeText(link);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const handleSendQRCode = (formID) => {
    setFormIdToSend(formID);
    setShowQRModal(true);
  };

  const handleDeleteForm = async (formId) => {
    try {
      await deleteDoc(doc(db, 'forms', formId));
      setForms(forms.filter(form => form.id !== formId));
    } catch (error) {
      console.error('Error deleting form:', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="w-full max-w-3xl p-6 bg-white rounded-lg shadow-lg m-3">
        <h2 className="text-3xl font-bold mb-4 text-center">Admin Dashboard</h2>
        <div className="grid grid-cols-1 md:grid-cols-5 md:gap-2 mb-4">
          <input
            type="text"
            placeholder="Form Name"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
            className="w-full col-span-4 border border-gray-300 rounded-md p-3 mb-2 lg:mb-0"
          />
          <button
            onClick={handleCreateForm}
            className="bg-primary col-span-1 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            Create Form
          </button>
        </div>
        <h3 className="text-2xl font-bold mb-4">Your Forms</h3>
        <ul className="space-y-4">
          {forms.map((form) => (
            <li key={form.id} className="bg-gray-50 p-4 rounded-md shadow-md flex flex-col md:flex-row justify-between items-start md:items-center">
              <div className="flex-1 mb-2 md:mb-0">
                <span className='font-semibold'>{form.formName}</span>
                <span className="text-sm text-gray-500 ml-2">(ID: {form.id})</span>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleViewResults(form.id)}
                  className="bg-green-600 text-white py-1 px-3 rounded-lg hover:bg-green-700 transition-colors duration-300"
                >
                  View Results
                </button>
                <button
                  onClick={() => handleSendQRCode(form.id)}
                  className="bg-blue-600 text-white py-1 px-3 rounded-lg hover:bg-blue-700 transition-colors duration-300"
                >
                  Send
                </button>
                <button
                  onClick={() => handleEditForm(form.id)}
                  className="text-yellow-600 py-1 px-3 rounded-lg transition-colors duration-300"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  onClick={() => handleDeleteForm(form.id)}
                  className="text-red-600 py-1 px-3 rounded-lg transition-colors duration-300"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {showQRModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-4 text-center">QR Code and Link for Form</h3>
            <div className="flex justify-center mb-4">
              <QRCode value={`/form/${formIdToSend}`} />
            </div>
            <div className="flex items-center justify-center mb-4">
              <input
                type="text"
                value={`${window.location.origin}/form/${formIdToSend}`}
                readOnly
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              <button
                onClick={handleCopyLink}
                className="ml-2 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-300"
              >
                {copySuccess ? 'Copied!' : 'Copy'}
              </button>
            </div>
            <div className="flex justify-center">
              <button
                onClick={() => setShowQRModal(false)}
                className="bg-red-600 text-white py-2 px-4 rounded-lg transition-colors duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;

